import { Avatar } from "@mui/material";
import "./recommendation_component.scss";

export default function RecommendationsComponent(props) {
    return (
        <div>
            <div className="profileOuterClass">
                <Avatar
                    className="avatar"
                    src={props.profilePhoto}
                    sx={{ height: 56, width: 56 }}
                    style={{ border: "3px solid white" }}
                    alt={ `${props.name} appreciating Sam Varghese`}
                />

                <div className="profileInnerClass">
                    <p>
                        <span className="recommenderName">{props.name}</span>
                        <hr />
                        {/* <br /> */}
                        <span className="recommenderDesignation">
                            {props.designation}
                        </span>
                    </p>
                </div>
            </div>
            <img
                src={require("../../images/quote.png")}
                className="quote-start"
                alt="Quote start"
            />
            <p className="sentence">{props.recommendation}</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                    src={require("../../images/quote.png")}
                    className="quote-end"
                    alt="Quote end"
                />
            </div>
            <p className="verifyStatement">
                Via{" "}
                <a href={ props.link} target="_blank">
                    <span className="verifyLink">{props.source}</span>
                </a>
            </p>
        </div>
    );
}