import AchievementComponent from "./achievement_component";
import React, { forwardRef } from "react";
import "./achievement.scss";

var achievements_data = [
    {
        title: "Smart India Hackathon'23",
        title_sub_heading: "National Winner | Tamil Nadu",
        imagePath: require("../../images/prize.jpg"),
        description:
            "Sam led the first winning team at SIH from University. He got warded with a ₹1,00,000 cash prize.",
        link: "https://drive.google.com/file/d/15_JL_ylzJXEZnKbGst-_XKyTcGglnu7T/view?usp=sharing",
        rank: "gold",
        buttonText: "News",
    },
    {
        title: "₹4,50,000 Grant Recipient",
        title_sub_heading: "Yukti Challenge, AICTE | New Delhi",
        imagePath: require("../../images/aicte.jpg"),
        description:
            "Subsequently Sam won the National challenge among 36,000+ teams consisting of SIH Winners and selected startup teams.",
        link: "https://www.wikiwand.com/en/KPMG",
        rank: "gold",
        buttonText: "More",
    },
    {
        title: "Kavach Hackathon",
        title_sub_heading: "Grand Finalist | Mumbai",
        imagePath: require("../../images/kavach.jpg"),
        description:
            "Sam was awarded by Superintendent of Mahrashtra Police, and the Director of AICTE. Offered an internship at Mahrashtra Police Cyber Cell.",
        link: "https://www.wikiwand.com/en/KPMG",
        rank: "silver",
        buttonText: "More",
    },
    {
        title: "Interviewed by MoE",
        title_sub_heading: "Ministry of Education | New Delhi",
        imagePath: require("../../images/MoE_Interview.png"),
        description:
            "Ministry of Education, Govt of India, exclusively interviewed Sam's team for their achievements in national competitions.",
        link: "https://youtu.be/jZPjKlnnCqI?si=1qz0Yl6w3Uk94ndr",
        rank: "bronze",
        buttonText: "Interview",
    },
    {
        title: "Best Internship of Campus",
        title_sub_heading: "NMIMS | Indore",
        imagePath: require("../../images/xperia.jpg"),
        description:
            "Sam bagged the best internship experience award at Xperia, NMIMS (The annual summer internship competition)",
        link: "https://www.linkedin.com/posts/sam-varghese2003_ecstatic-to-share-that-ive-won-the-prize-activity-7262778481845960704-4mu2?utm_source=share&utm_medium=member_desktop",
        rank: "bronze",
        buttonText: "More",
    },
    {
        title: "'A' Grade NCC Cadet",
        title_sub_heading: "National Cadet Corps",
        imagePath: require("../../images/ncc.jpg"),
        description:
            "Sam also achieved the highest grades for his performance in the NCC Exams. Unit: 2 MP Armd Sqn, Indore of the MP and Chhattisgarh Directorate",
        link: "#",
        rank: "bronze",
        buttonText: "More",
    },
];

const Achievements = forwardRef((props, ref) => {
    return (
        <div ref={ref} className="achievement_section">
            <h1>Highlights</h1>
            {achievements_data.map((element, index) => (
                <AchievementComponent
                    imagePath={element.imagePath}
                    title={element.title}
                    title_sub_heading={element.title_sub_heading}
                    description={element.description}
                    link={element.link}
                    rank={element.rank}
                    buttonText={element.buttonText}
                    key={`${element.title}-${index}`}
                />
            ))}
        </div>
    );
}
)

export default Achievements;