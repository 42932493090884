export default function PersonalDescription() {
    return (
        <p style={{ textAlign: "center", padding: "2vw", marginTop: "2vw" }}>
            <h1>SAM VARGHESE</h1>
            <br />
            2x National Winner 🏆 | Intern at Big Fours
            <br />
            Grant Recipient | 5 ⭐ Coder
            <br />
            Featured in Times of India
        </p>
    );
}
